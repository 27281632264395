import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import VideoList from 'components/VideoList';

export default function VideosPage(props) {
  let {
    data: {
      allContentfulVideo: { nodes: videos },
    },
  } = props;

  return (
    <Layout>
      <VideoList videos={videos} />
    </Layout>
  );
}

export const query = graphql`
  query {
    allContentfulVideo {
      nodes {
        ...ContentfulVideoFields
      }
    }
  }
`;
