import React, { useState } from 'react';

import { getVideoUrl } from 'helpers/url';
import { getYouTubeId, getEmbedUrl, getPreviewUrl } from 'helpers/youtube';
import { multiline, dateFormat } from 'helpers/text';

import {
  Spacing,
  Slider,
  SliderVideo,
  SharedStyles,
  Container,
  ListOfVideo,
  ButtonPlay,
  PageTopicsControl,
  VideoLightBox,
} from '@matchbox-mobile/kenra-storybook';

const {
  StSliderVideoWrap,
  StSliderVideo,
  StSliderVideoModal,
  StSliderVideoElm,
  StSliderVideoDesc,
} = SliderVideo;

const { StSectionTitle } = SharedStyles;

const ALL_TOPICS = 'All topics';

function TopVideos({ topVideos }) {
  const [openedModal, setOpenedModal] = useState(null);

  if (!Array.isArray(topVideos) || topVideos.length === 0) return null;

  return (
    <>
      <StSliderVideoWrap>
        <Slider
          hideProgress
          settings={{
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: true,
          }}
        >
          {topVideos.map(video => {
            let { id, title, date, youtubeLink } = video;
            let youTubeId = getYouTubeId(youtubeLink);

            return (
              <StSliderVideo key={id} onClick={() => setOpenedModal(id)}>
                <StSliderVideoElm>
                  <ButtonPlay useImgOverlay bgImg={getPreviewUrl(youTubeId)} />
                </StSliderVideoElm>
                <StSliderVideoDesc>
                  <p>{multiline(title.title)}</p>
                  {date && <h5>{dateFormat(date)}</h5>}
                </StSliderVideoDesc>
              </StSliderVideo>
            );
          })}
        </Slider>
      </StSliderVideoWrap>
      <StSliderVideoModal>
        {topVideos.map(video => {
          let { id, youtubeLink } = video;
          let youTubeId = getYouTubeId(youtubeLink);
          let youTubeEmbed = getEmbedUrl(youTubeId);
          return (
            <VideoLightBox
              key={id}
              onHide={() => setOpenedModal(null)}
              isOpen={openedModal === id}
              channel="youtube"
              videoOptions={[
                {
                  src: youTubeEmbed,
                },
              ]}
            />
          );
        })}
      </StSliderVideoModal>
    </>
  );
}

export default function Videos(props) {
  let { videos } = props;

  let topVideos = videos.filter(v => !!v.showOnVideosList);
  let topics = new Set(videos.map(v => v.topic).filter(Boolean));

  const [selectedTopic, setSelectedTopic] = useState(null);

  let filteredVideos = videos.filter(v => {
    if (
      selectedTopic &&
      selectedTopic !== ALL_TOPICS &&
      selectedTopic !== v.topic
    )
      return false;
    return true;
  });

  return (
    <>
      <Spacing removeSpaceTop removeSpaceBottom>
        <TopVideos topVideos={topVideos} />
      </Spacing>

      <Spacing removeSpaceBottom>
        <Container>
          <PageTopicsControl
            mobileFixedMenu
            inversionColor
            styleArrowType2
            useSelectedTitle
            title="TOPICS"
            onChange={topic => setSelectedTopic(topic)}
            items={[ALL_TOPICS, ...topics].map(topic => ({
              title: topic,
              val: topic,
            }))}
          />
          <StSectionTitle alignLeft>
            <h3>LATEST VIDEOS</h3>
          </StSectionTitle>
        </Container>
      </Spacing>

      {/* LIST */}

      <ListOfVideo
        items={filteredVideos.map(video => {
          let { youtubeLink, title, slug, type } = video;
          let youTubeId = getYouTubeId(youtubeLink);

          return {
            img: getPreviewUrl(youTubeId),
            title: multiline(title.title),
            subtitle: type,
            href: getVideoUrl(slug),
          };
        })}
      />
    </>
  );
}
